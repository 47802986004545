<template>
    <div>
        <el-card class="box-card">
            <!--顶部面包靴-->
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/volunteer/volunteerManage' }" class="fs20">志愿者管理</el-breadcrumb-item>
                <el-breadcrumb-item class="fs20">积分记录({{ $route.query.volunteerName }})</el-breadcrumb-item>
                <el-breadcrumb-item class="fs20">积分明细</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="search-table-box mt30">
                <div>
                    <el-form ref="form" :inline="true" :model="queryParams" label-width="80px">
                        <el-form-item label="积分体系">
                            <el-select v-model="queryParams.integralSystemId" placeholder="请选择" clearable>
                                <el-option :label="item.systemName" :value="item.id" v-for="item in integralSystemList" :key="item.id"> </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="积分变动">
                            <el-select v-model="queryParams.integralOperation" placeholder="请选择" clearable>
                                <el-option :label="item.name" :value="item.val" v-for="item in integralOperateList" :key="item.val"> </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="searchList">查询</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <el-table v-loading="loading" style="width: 100%; margin-top: 10px" :data="tableData" border :header-cell-style="headerCellStyle">
                    <template slot="empty">
                        <IsEmpty />
                    </template>
                    <el-table-column type="index" label="序号" width="50" align="center" />
                    <el-table-column prop="systemName" label="积分体系" align="center" />
                    <el-table-column prop="groupName" label="志愿团体" align="center" />
                    <el-table-column prop="integralOperation" label="变动积分" align="center" />
                    <el-table-column prop="integralChange" label="变动积分数" align="center" />
                    <el-table-column prop="description" label="变动原因" align="center" />
                    <el-table-column prop="operationAccount" label="操作账号" align="center" />
                    <el-table-column prop="createTime" label="操作时间" align="center" />
                </el-table>
                <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="queryParams.pageNum"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="queryParams.pageSize"
                    layout="total, sizes, prev, pager, next"
                    :total="total"
                >
                </el-pagination>
            </div>
        </el-card>
    </div>
</template>

<script>
import { getVolunteerIntegralRecordList } from '@/api/integralRecord'
import { getIntegralRecordList } from '@/api/integralSystem'
export default {
    components: {},
    data() {
        return {
            integralOperateList: [
                { name: '增加', val: 1 },
                { name: '减少', val: 2 },
                { name: '转让', val: 3 },
                { name: '兑换', val: 4 }
            ],
            loading: false,
            tableData: [],
            queryParams: {
                //查询参数
                pageNum: 1,
                pageSize: 10,
                systemName: '',
                volunteerId: '',
                integralOperation: '',
                integralSystemId: ''
            },
            total: 0,
            integralSystemList: []
        }
    },
    mounted() {
        this.queryParams.volunteerId = Number(this.$route.query.volunteerId)
        this.getList()
        this.getIntegralRecordList()
    },
    methods: {
        async getIntegralRecordList() {
            const res = await getIntegralRecordList()
            this.integralSystemList = res.data
        },
        async getList() {
            this.loading = true
            const res = await getVolunteerIntegralRecordList(this.queryParams)
            this.loading = false
            this.tableData = res.data.rows
            this.total = res.data.total
        },
        searchList() {
            this.queryParams.pageNum = 1
            this.getList()
        },
        handleSizeChange(pageSize) {
            this.queryParams.pageSize = pageSize
            this.queryParams.pageNum = 1
            this.getList()
        },
        handleCurrentChange(pageNum) {
            this.queryParams.pageNum = pageNum
            this.getList()
        }
    }
}
</script>

<style lang="scss" scoped>
.box-card {
    min-height: calc(100% - 10px);
}
.mt30 {
    margin-top: 30px;
}
.fs20 {
    font-size: 14px;
}
.red {
    color: rgb(255, 90, 89);
}
.search-table-box {
    .down-export {
        float: right;
        margin-bottom: 20px;
        display: flex;
        button {
            margin-left: 10px;
        }
        button:first-child {
            margin: 0 10px;
        }
    }
}
/deep/ .upload-demo {
    text-align: center;
}
</style>
